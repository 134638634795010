import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../../utils/typography"
import Tags from "../post/tags/tags"

const PostListItem = ({ post }) => {
  const { fields, frontmatter, excerpt } = post
  const { slug } = fields
  const { title, description, date, readingTime } = frontmatter
  const displayTitle = title || slug
  const tags = frontmatter.tags ?? []
  return (
    <article key={slug}>
      <header>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
            fontWeight: 200,
          }}
        >
          <Link style={{ boxShadow: `none` }} to={slug}>
            {displayTitle}
          </Link>
        </h3>
        <small>
          <span role="img" aria-label="calendar">
            📅
          </span>
          {date}
          {" | "}
          <span role="img" aria-label="calendar">
            ☕️
          </span>
          {readingTime}
        </small>
      </header>
      <section>
        <small>
          <Tags tags={tags} />
        </small>
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: description || excerpt,
            }}
          ></span>
          <br />
          <Link to={slug}> {` `} Read →</Link>
        </p>
      </section>
    </article>
  )
}

PostListItem.defaultProps = {
  post: {},
}

PostListItem.propTypes = {
  post: PropTypes.object,
}

export default PostListItem
