import PropTypes from 'prop-types'
import React from 'react'
import Tag from './tag'

const Tags = ({tags}) => {
  return (
    <span>
      {(tags || [])
        .sort()
        .map((tag, index) => (
          <Tag tag={tag} key={index} />
        )
      )}
    </span>
  )
}

Tags.defaultProps = {
  tags: [],
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default Tags
