import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Tag = ({ tag }) => (
  <Link to={`/tag/${tag}`}>
    <small key={tag}>
      <span role="img" aria-label="tag">
        🏷️
      </span>
      #{tag}
      <span> </span>
    </small>
  </Link>
)

Tag.defaultProps = {
  tag: "",
}

Tag.propTypes = {
  tag: PropTypes.string,
}

export default Tag
