import PropTypes from "prop-types"
import React from "react"
import "./post-list-item"
import PostListItem from "./post-list-item"

const PostList = ({ posts }) => (
  <div>
    {posts.map((post, index) => (
      <PostListItem post={post} key={index} />
    ))}
  </div>
)

PostList.defaultProps = {
  posts: {},
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
}

export default PostList
